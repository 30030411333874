import Vue from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "bootstrap"
import './registerServiceWorker'
import router from './router'
import './assets/fonts/icons.js'
import store from './store'
import './Global Variables/GlobalVariables'
import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.component('loading', Loading);


Vue.use(VueGoodTablePlugin);
Vue.config.productionTip = false;
Vue.use(VueSweetalert2);


new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
