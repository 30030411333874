import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../components/Login.vue'
import LoginView from "@/views/LoginView";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component : LoginView ,
    },
    {
        path: '/KazangAccounts/:id',
        name: 'KazangAccounts',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/KazangAccounts.vue')
        }
    },
    {
        path: '/Reports',
        name: 'Reports',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/Reports.vue')
        }
    },
    {
        path: '/Schedules',
        name: 'Schedules',
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/Schdules.vue')
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
