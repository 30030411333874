import Vue from 'vue'

import {utils} from './utils';
import {responses} from "./responses";
import {errorHandler} from "./errorHandler";
import Swal from "sweetalert2";
window.Kazangutils =`https://realtime.abc.co.zm:8443/kazangApiTest/api/`;
// window.Kazangutils =`http://192.168.30.10:8080/kazangApi/api/`;

window.responses = responses;
window.errorHandler = errorHandler
window.$utils = utils;
Vue.prototype.$utils = utils;
window.Swal = Swal;

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500,
    showCloseButton: true,
    customClass: 'toastr',
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});
window.Toast = Toast;