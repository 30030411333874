import Vue from 'vue'
import Vuex from 'vuex'
import timeModule from './modules/time-module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading : false,
  },
  getters: {
    getLoadingState: (state) => state.loading,
  },
  mutations: {
    setLoadingState: (state, payload) => state.loading = payload,
  },
  actions: {
  },
  modules: { timeModule }
})
