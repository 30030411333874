<template>
  <div class="home">
    <div class="banner"></div>
    <LoginForm/>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginForm from '@/components/Login.vue'

export default {
  name: 'HomeView',
  components: {
    LoginForm
  }
}
</script>
<style lang="scss">
.home{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
