<template>
  <div id="app">
    <Loader />
    <router-view/>
  </div>
</template>

<style lang="scss">
@import "assets/style.scss";
#app {
  height: 100%;
}

#app > div > div.content-wrap {
  overflow-y: auto;
}

</style>
<script>
import Loader from "@/components/utils/Loader";
export default {
  components: {Loader}
}
</script>